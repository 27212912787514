import defaultAddress from '../../../configs.js'
const state = {
  email: '',
  rememberMe: '',
  errors: null,
  successMessage: null,
  redirectAddress: '',
  defaultAddress: `${defaultAddress.defaultAddress}`,
  confirmed_flag: false
}

const mutations = {
  emailChecker (state, email) {
    state.email = email
  },
  rememberMeLoader (state, rememberMe) {
    state.rememberMe = rememberMe
  },
  errorsFetcher (state, errors) {
    state.errors = errors
  },
  clearError (state) {
    state.errors = ''
  },
  successMessageFetcher (state, message) {
    state.successMessage = message
  },
  messageCleaner (state, message) {
    state.successMessage = ''
  },
  getRedirectAddress (state, address) {
    state.redirectAddress = address
  },
  confirmFlagUpd (state) {
    state.confirmed_flag = true
  }
}

const actions = {
}

export default {
  state, actions, mutations
}
