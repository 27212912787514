<template>
  <div class="auth__card__content">
    <!-- <div class="card__logo--mobile">
      <img src="../assets/domeo-logo-mobile.svg" alt="">
    </div> -->
    <div class="card__logo">
      <img src="../assets/domeo-logo.svg">
    </div>
    <div class="card__title">
      Вход
      <div class="error__block" v-if="errors">
        <p class="error__text">{{ errors.message }}</p>

      </div>
      <div class="message__block" v-if="messages">
        <p class="message__text">{{ messages.message }}</p>
      </div>
    </div>
    <router-link :to="{name:'Pwreboot'}">
      <div class="auth__recovery">
        <a class="string__btn" href="">Забыли пароль?</a>
      </div>
    </router-link>
    <div class="form__group">
      <div class="password">
        <div class="form__input">
          <div class="form__label--pass">
            <img src="../assets/pass.svg">
          </div>
          <input v-if="typePasswordField" type="password" class="input__contact"
                 placeholder="Пароль" :value="password" @input="changePassword">
          <input v-if="!typePasswordField" type="text" class="input__contact"
                 placeholder="Пароль" :value="password" @input="changePassword">
          <input type="checkbox" class="visibility__input" id="password-checkbox" title="pass">
          <label class="visibility__label" for="password-checkbox" @click="ChangeTypePasswordField"></label>
        </div>
      </div>
      <label class="form__remember">
        <input type="checkbox" class="form__checkbox" name="remember" @change="changeRemember" checked
               id="remember_checkbox">
        <span class="form__custom-checkbox"></span>
        <span>Запомнить меня</span>
      </label>
      <button class="btn btn--auth" :class="{'btn--auth_active': password}" type="submit" @click="sendPassword"
              id="next">Далее
      </button>
    </div>
    <router-link :to="{name:'First'}">
      <button class="string__btn string__btn--back" type="button">
        <img src="../assets/auth_back.svg" class="label__back">
        <p>Назад</p>
      </button>
    </router-link>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import authApi from '@/api/authApi'

export default {
  name: 'Login',
  data () {
    return {
      typePasswordField: true,
      remember: true,
      password: ''
    }
  },
  computed: {
    ...mapState({
      messages: state => state.Auth.successMessage,
      errors: state => state.Auth.errors,
      email: state => state.Auth.email,
      redirectUrl: state => state.Auth.redirectAddress,
      defaultUrl: state => state.Auth.defaultAddress
    }),
    confirmedFlag: function () {
      if ('confirmed_flag' in this.$route.query) {
        return !!this.$route.query.confirmed_flag
      }
      return null
    },
    visible: function () {
      return !!this.errors
    }
  },
  methods: {
    changePassword () {
      this.password = event.target.value
    },
    ChangeTypePasswordField () {
      this.typePasswordField = !this.typePasswordField
    },
    sendPassword () {
      this.$store.commit('messageCleaner')
      this.$store.commit('clearError')
      if (this.password.length > 0) {
        const credentials = { email: this.email, password: this.password, remember: this.remember }
        if (this.confirmedFlag) {
          credentials.confirmed_flag = this.confirmedFlag
        }
        authApi.login(credentials).then(response => {
          if (this.redirectUrl) {
            window.location.replace(this.redirectUrl)
          } else {
            window.location.replace(this.defaultUrl)
          }
        }).catch(result => {
          console.log(result)
          this.$store.commit('errorsFetcher', result.response.data)
        })
      } else {
        this.$store.commit('errorsFetcher', { message: 'Поле не может быть пустым' })
      }
    },
    fetchEmailOnUrl () {
      if (this.$route.query.email) {
        this.$store.commit('emailChecker', this.$route.query.email)
      }
    },
    changeRemember () {
      this.remember = !this.remember
    },
    protectEmail () {
      if (!this.email) {
        this.$router.push('/')
      }
    }
  },
  mounted () {
    this.$store.commit('clearError')
    this.fetchEmailOnUrl()
    this.protectEmail()
  }
}

</script>
