<template>
  <div class="auth__card__content">
    <!-- <div class="card__logo--mobile">
      <img src="../assets/domeo-logo-mobile.svg">
    </div> -->
    <div class="card__logo">
      <img src="../assets/domeo-logo.svg">
    </div>
    <div class="card__title">
        Вход
      <div class="error__block" v-if="errors">
        <p class="error__text">{{errors.message}}</p>
      </div>
    </div>
    <router-link :to="{name:'Pwreboot'}">
      <div class="auth__recovery">
          <a class="string__btn" href="">Забыли пароль?</a>
      </div>
    </router-link>
    <div class="form__group">
      <div class="form__input">
        <div class="form__label">
          <img src="../assets/contact.svg">
        </div>
        <input id="contact" type="email" name="email" class="input__contact"
              placeholder="Email или телефон"
              @input="emailChange"
              :value="email">
        </div>
        <button class="btn btn--auth" :class="{'btn--auth_active': email}"  @click="pushEmail" >Далее</button>
    </div>
      <div class="registration">
          <router-link  :to="{name:'Register'}">
            <button class="string__btn string__btn--registration" >Регистрация</button>
          </router-link>
      </div>
    </div>
</template>

<script>
import authApi from '@/api/authApi'
import { mapState } from 'vuex'

export default {
  name: 'First',
  data () {
    return { email: '' }
  },
  computed: {
    ...mapState({
      errors: state => state.Auth.errors,
      confirmed_flag: state => state.Auth.confirmed_flag
    })
  },
  methods: {
    emailChange () {
      this.email = event.target.value
    },
    pushEmail () {
      this.$store.commit('clearError')
      if (this.email.length > 0) {
        return new Promise(resolve => {
          authApi.register({ email: this.email, service: 'inpEmail', confirmed_flag: this.confirmed_flag })
            .then(response => {
              this.$store.commit('emailChecker', this.email)
              this.$router.push('/Login')
            })
            .catch(result => {
              this.$store.commit('errorsFetcher', result.response.data)
            })
        })
      } else {
        this.$store.commit('errorsFetcher', { message: 'Поле не может быть пустым' })
      }
    },
    getUrl () {
      this.$store.commit('getRedirectAddress', this.$route.query.address)
    },
    confirmedFlag () {
      const routerConf = this.$route.query.confirmed_flag
      if (routerConf) {
        this.$store.commit('confirmFlagUpd')
      }
    }
  },
  mounted () {
    this.confirmedFlag()
    this.$store.commit('clearError')
    this.getUrl()
  }
}
</script>

<style scoped>

</style>
