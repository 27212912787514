import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login'
import Register from '@/views/Register'
import First from '@/views/First'
import PwReboot from '@/views/PwReboot'
import WaitEmail from '@/views/WaitEmail'

const routes = [
  {
    path: '/',
    name: 'First',
    component: First
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/pwreboot',
    name: 'Pwreboot',
    component: PwReboot
  },
  {
    path: '/waitEmail',
    name: 'waitEmail',
    component: WaitEmail
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
