import axios from 'axios'
import baseUrl from '../../configs.js'

axios.defaults.withCredentials = true
const login = credentials => {
  return axios.post(`${baseUrl.baseUrl}/JwtInit`, credentials, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    credentials: 'same-origin'
  })
}
const register = credentials => {
  return axios.post(`${baseUrl.baseUrl}/email`, {
    email: credentials.email,
    service: credentials.service,
    confirmed: credentials.confirmed_flag
  })
}
const clac = token => {
  return axios.get(`${baseUrl.baseUrl}/JwtInit`, {
    cookies: {
      Authorization: `Bearer ${token}`
    }
  })
}

export default {
  login, register, clac
}
