<template>
    <div class="auth">
      <div class="container">
        <div class="desktop-decoration">
          <img src="./assets/squares.png">
        </div>
        <router-view :password="password" :email="email"
                    @changePassword="changePass"
                    @removePassword="rePassword"
                    @emailChange = 'changeEmail'

        />
      </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    changePass (pass) {
      this.password = pass
    },
    rePassword (pass) {
      this.password = pass
    },
    changeEmail (email) {
      this.email = email
    }
  }
}
</script>
<style>
.container {
  max-width: 1212px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth {
  font-family: 'Roboto', sans-serif;
}
.auth_back {
  height: 100%;
  /* width: 100%; */
  background: rgb(255,255,255);
  background: linear-gradient(135deg, rgba(255,255,255,1) 60%, rgba(255,216,0,1) 60%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* text-align: center; */
  /* padding: 0 1rem; */
}

.auth {
  width: 100%;
  max-width: 60rem;
  height: 56.8rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #E4E7EC;
  position: relative;
  margin: 0 auto;
  z-index: 1000;
  overflow: hidden;
}
@media (max-device-height: 600px) {
  .auth {
    height: 90%;
  }
}
@media (max-width: 576px) {
  .auth {
    margin: 0 2rem;
    max-width: none;
    max-height: none;
    width: auto;
  }
}

.auth__card {
  height: 100%;
  padding: 10rem 0.5rem;
}

.auth__card__content {
  max-width: 35.5rem;
  padding: 3.9rem 1.9rem 1.9rem;
  margin: 0 auto;
  position: relative;
  height: 88%;
}

/* @media (max-device-height: 740px) {
  .auth__card__content {
    justify-content: center;
  }
} */

.card__title {
  color: #000;
  font-family: inherit;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: -0.5pt;
  margin-bottom: 3.6rem;
  height: 4rem;
  text-align: center;

  position: relative;
}

.card__logo {
  width: 13.9rem;
  margin: 0 auto;
  height: 7.5rem;
  padding-left: 5rem;
  margin-bottom: 3rem;
}

.card__logo,
.card__logo--mobile {
  position: relative;
}

/* .card__logo--mobile {
  width: 13.9rem;
  margin: 0 auto;
  height: 7.5rem;
  padding-left: 5rem;
  margin-bottom: 3rem;
} */

.card__logo img {
  margin: 0 auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.auth__recovery {
  margin: 1.1rem 0;
  text-align: left;
}

.string__btn {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #808080;
  text-decoration: none;
}
.string__btn:hover {
  color: #616161;
}
.string__btn--registration {
  line-height: 1.8;
  text-decoration: underline;
  display: block;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  background: none;
}
.string__btn--header {
  font-size: 1.6rem;
  text-decoration: none;
}
@media (max-width: 576px) {
  .string__btn--header {
    color: #ffffff;
    font-size: 1.4rem;
    text-decoration: underline;
  }
}
.message__block {
  position: absolute;
  top: 80%;
  left: 0;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EAFCF1;
}
.message__text {
  font-size: 1.4rem;
  font-weight: 400;
  color: #084D23;
}
.error__block {
  position: absolute;
  top: 80%;
  left: 0;
  width: 100%;
  height: 4rem;
  background-color: #FFE8E1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error__text {
  font-size: 1.4rem;
  font-weight: 400;
  color: #841A21;
}
.string__btn--back {
  line-height: 1.8;
  border: none;
  cursor: pointer;
  background: none;
  position: absolute;
  left: 1rem;
  bottom: 2rem;
  /* margin-top: 2rem; */
  display: flex;
}
.btn {
  /* padding: 1.5rem 11rem; */
  max-width: 272px;
  width: 100%;
  height: 46px;
  background: #C4C4C4;
  border: none;
  cursor: pointer;
  border-radius: 3rem;
  font-family: inherit;
  font-size: 1.8rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  box-shadow: 0px 7px 30px #C4C4C4, 0px 7px 12px rgba(255, 255, 255, 0.3);
  display: block;
  margin: 0 auto;
}
.btn--auth {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem;
}
.btn--auth_register {
  padding: 0;
  width: 27.5rem;
  height: 4.4rem;
}

.btn--auth_active {
  background-color: #FFD800;
  color: black;
  box-shadow: 0px 7px 20px rgba(255, 255, 255, 0.3), 0px 6px 29px #FDCF58;
}

input[type="password" i] {
    -webkit-text-security: disc !important;
    padding: 1px 2px;
}
input[type="checkbox" i] {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
}
.form__input {
  display: flex;
  align-items: center;
  width: 92%;
  height: 5rem;
  padding: 0 1.5rem;
  background-color: #F4F5F7;
  margin-bottom: 1rem;
}
.form__checkbox {
  display: none;
}
.form__custom-checkbox {
  position: relative;
  width: 1.7rem;
  height: 1.7rem;
  outline: 1px solid rgb(156, 156, 156);
  transition: all 0.5s ease;
  margin-right: 1rem;
  margin-bottom: 0.3rem;
  border-radius: 3px;
}
.form__custom-checkbox::before {
  opacity: 0;
  transition: all 0.5s ease;
  position: absolute;
  content: " ";
  width: 1.2rem;
  height: 0.2rem;
  background-color: black;
  border-radius: 5px;
  transform: rotate(-45deg);
  top: 7px;
  right: 0;
}
.form__custom-checkbox::after {
  opacity: 0;
  transition: all 0.5s ease;
  position: absolute;
  content: " ";
  width: 0.8rem;
  height: 0.2rem;
  background-color: black;
  border-radius: 5px;
  transform: rotate(45deg);
  top: 9px;
  left: 1px;
}

.form__checkbox:checked + .form__custom-checkbox {
  background-color: #FFD800;
  outline: none;
}
.form__checkbox:checked + .form__custom-checkbox::before,
.form__checkbox:checked + .form__custom-checkbox::after {
  opacity: 1;
}
.form__label {
  position: relative;
  width: 1.9rem;
  height: 1.7rem;
  margin-right: 1.5rem;
}
.form__label--pass {
  position: relative;
  margin-right: 1.5rem;
  width: 1.3rem;
  height: 1.7rem;
}
.form__remember {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #808080;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
}
.input__contact {
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 3px;
  border: none;
  font-family: inherit;
  font-size: 1.4rem;
  color: #000000;
}
.form__label img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.form__group {
    position: relative;
    border: none;
    box-shadow: none;
}
.label__back {
    margin: auto 0.5rem;
    height: 1.4rem;
}

.visibility__input {
    appearance: none;
    -webkit-appearance: none;
    position: absolute;
    opacity: 0;
    border: none;
    outline: none;
}
body{
      height: 100%;
    width: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.6rem;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    background-color: #e5e5e5;
}
.visibility__label:before {
  content: "";
  display: block;
  height: 2rem;
  width: 2rem;
  background: url("assets/non-visibility.svg") no-repeat;
  position: absolute;
  top: 1.7rem;
  right: 1rem;
  z-index: 1;
  transition: opacity 0.1s linear;
}

.visibility__label:after {
  content: "";
  display: block;
  height: 2rem;
  width: 2rem;
  background: url("assets/visibility.svg") no-repeat;
  opacity: 0;
  position: absolute;
  top: 1.7rem;
  right: 1rem;
  z-index: 2;
  transition: opacity 0.1s linear;
}

.visibility__input:checked + .visibility__label:before {
  background-color: #fff;
  opacity: 0;
}

.visibility__input:checked + .visibility__label:after {
  opacity: 1;
  transition: opacity 0.1s linear;
}

/**
  Desktop
*/

.desktop-decoration {
  display: none;
}

@media (min-width: 1140px) {
  .auth {
    max-width: 100%;
    /* width: 90%; */
    height: 100%;
    background: rgb(255,216,0);
    background: linear-gradient(130deg, rgba(255,216,0,1) 38%, rgba(255,255,255,1) 38%);

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 130px;
    box-sizing: border-box;
  }

  .container {
    margin: 0 auto;
    width: 100%;
    max-height: 800px;
    justify-content: space-between;
  }

  .auth_back {
    /* padding: 10px; */
    box-sizing: border-box;
    background: rgb(228,231,236);
    background: linear-gradient(130deg, rgba(228,231,236,1) 39.8%, rgba(255,255,255,1) 39.8%);
  }

  .auth__card__content {
    padding: 3.9rem 1.9rem 3.9rem 1.9rem;
    box-shadow: 0px 0px 8px #E4E7EC;
    margin: 0;
    /* padding-top: 0; */
    width: 31.7rem;
    height: 80%;
  }

  .card__logo--mobile {
    display: none;
  }

  .card__logo {
    display: block;
    width: 20.7rem;
    height: 12.4rem;
    margin: 0 auto;
    /* padding-left: 0; */
    margin-bottom: 7.3rem;
  }

  .desktop-decoration {
    display: block;
    position: relative;
    width: 500px;
    height: 500px;
  }

  .desktop-decoration img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
