<template>
  <div class="auth__card__content">
    <!-- <div class="card__logo--mobile">
      <img src="../assets/domeo-logo-mobile.svg" alt="">
    </div> -->
    <div class="card__logo">
      <img src="../assets/domeo-logo.svg">
    </div>
    <div class="card__title">
      Вход
      <div class="error__block" v-if="errors">
        <p class="error__text">{{errors.message}}</p>
      </div>
    </div>
    <div class="form__group">
      <div class="form__input">
        <div class="form__label">
          <img src="../assets/contact.svg">
        </div>
        <input id="contact" type="email"
                  name="email"
                  class="input__contact"
                  placeholder="Email"
                  :value="email"
                  @input="emailChange" >
        </div>
        <button class="btn btn--auth" :class="{'btn--auth_active': email}" @click="rePassword" >Восстановить</button>
        <!-- <button class="btn btn--auth" :class="{'btn--auth_active': email}"  @click="pushEmail" >Далее</button> -->
    </div>
    <div class="registration">
        <router-link  :to="{name:'Register'}">
          <button class="string__btn string__btn--registration" >Регистрация</button>
        </router-link>
    </div>
      <!-- <div class="form__group">
          <img src="../assets/contact.svg" class="form__label">
          <input id="contact" type="email"
                  name="email"
                  class="input__contact"
                  placeholder="Email"
                  :value="email"
                  @input="emailChange" >
          <button class="btn" @click="rePassword" >Восстановить</button>
      </div> -->
    <!-- <div>
      <button class="string__btn string__btn--back" type="button" @click="emailRemove" onclick="javascript:history.back()">
          <img src="../assets/auth_back.svg" class="label__back">
          <p>Назад</p>
      </button>
    </div> -->
    <button class="string__btn string__btn--back" type="button" @click="emailRemove" onclick="javascript:history.back()">
      <img src="../assets/auth_back.svg" class="label__back">
      <p>Назад</p>
    </button>
  </div>
</template>

<script>
import authApi from '@/api/authApi'
import { mapState } from 'vuex'

export default {
  name: 'PweReboot',
  data () {
    return {
      email: ''
    }
  },
  methods: {
    emailChange () {
      this.email = event.target.value
    },
    rePassword () {
      if (this.email.length > 0) {
        return new Promise(resolve => {
          authApi.register({ email: this.email, service: 'pwe_password', confirmed_flag: this.confirmed_flag })
            .then(response => {
              this.$store.commit('successMessageFetcher', response.data)
              this.$store.commit('emailChecker', this.email)
              this.$router.push('/waitEmail')
            })
            .catch(result => {
              this.$store.commit('errorsFetcher', result.response.data)
            })
        })
      } else {
        this.$store.commit('errorsFetcher', { message: 'Поле не может быть пустым' })
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.Auth.errors,
      messages: state => state.Auth.successMessage,
      confirmed_flag: state => state.Auth.confirmed_flag
    })
  },
  mounted () {
    this.$store.commit('clearError')
  }
}
</script>

<style scoped>

</style>
