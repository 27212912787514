<template>
  <div class="auth__card__content">
    <!-- <div class="card__logo--mobile">
      <img src="../assets/domeo-logo-mobile.svg" alt="">
    </div> -->
    <div class="card__logo">
      <img src="../assets/domeo-logo.svg">
    </div>
    <div class="card__title card__title--reboot">
        Вход
        <div class="error__block" v-if="errors">
          <p class="error__text">{{errors.message}}</p>
        </div>
    </div>
    <router-link :to="{name:'Pwreboot'}">
      <div class="auth__recovery">
        <a class="string__btn" href="">Забыли пароль?</a>
      </div>
    </router-link>
    <div class="form__group">
      <div class="form__input">
        <div class="form__label">
          <img src="../assets/contact.svg">
        </div>
        <input required="" id="contact" type="email" name="email"
                :value="email"
                class="input__contact"
                @input="emailChange" placeholder="Email" autofocus="">
      </div>
      <button @click="registrations" class="btn btn--auth btn--auth_register" :class="{'btn--auth_active': email}" >Зарегистрироваться</button>
    </div>
    <button class="string__btn string__btn--back" type="button">
      <router-link class="string__btn string__btn--back" :to="{name:'First'}">
        <img src="../assets/auth_back.svg" class="label__back">
        <p>Назад</p>
      </router-link>
    </button>
  </div>
</template>
<script>

import authApi from '@/api/authApi'
import { mapState } from 'vuex'
export default {
  name: 'Registration',
  data () {
    return {
      email: ''
    }
  },
  computed: {
    ...mapState({
      errors: state => state.Auth.errors,
      confirmed_flag: state => state.Auth.confirmed_flag
    })
  },
  methods: {
    emailChange () {
      this.email = event.target.value
    },
    registrations () {
      if (this.email.length > 0) {
        return new Promise(resolve => {
          authApi.register({ email: this.email, service: 'register', confirmed_flag: this.confirmed_flag })
            .then(response => {
              this.$store.commit('successMessageFetcher', response.data)
              this.$store.commit('emailChecker', this.email)
              this.$router.push('/waitEmail')
            })
            .catch(result => {
              this.$store.commit('errorsFetcher', result.response.data)
            })
        })
      } else {
        this.$store.commit('errorsFetcher', { message: 'Поле не может быть пустым' })
      }
    }
  },
  mounted () {
    this.$store.commit('clearError')
  }
}
</script>
<style>
.Danger{
  color: red;
  border: red;
  border-radius: 2rem ;
}
</style>
