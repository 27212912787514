<template>
  <h1 class="title" v-if="message">
    <div> {{message.message}}</div>
  </h1>
  <h1 class="title" v-else > Страница ожидания емейла </h1>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WaitEmail',
  computed: {
    ...mapState({
      message: state => state.Auth.successMessage
    })
  }
}
</script>

<style scoped>
</style>
